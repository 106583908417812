// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { Component } from 'react';

import * as Case from 'case';
import pluralize from 'pluralize';
import {
  getColumnLabel, getEquipmentId, getServiceLabel
} from 'services/Util';
import Highlight from 'react-highlighter';
import {
  Anchor,
  Box,
  Button,
  CheckBox, Text,
} from 'grommet';
import {
  Edit, FormClock,
} from 'grommet-icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import { chunk } from 'lodash';
import IDUtil from '../../../../shared/util/IDUtil';
import { AdvancedSwitch, instanceOf, matches } from '../../../../shared/util/AdvancedEnum';
import GLBMTooltip from '../../../../shared/component/GLBMTooltip';
import {
  ConfigurableComponent,
  EquipmentColumn,
  EquipmentIdColumn,
  LocationOption,
  ReportBy,
  ServiceColumn,
  TierOptions,
} from '../../../model';
import { StatusIcon } from '../../../../shared/component/StatusIcon';
import GLBMDataTable from '../../../../shared/component/GLBMDataTable';
import ResourceRevisionTooltip
  from './components/resource-revision/ResourceRevisionTooltip';
import { getEffectiveRevision } from './components/resource-revision/Util';

class ConfigureResourcesTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      equipmentList: [],
      sort: { ...props.sort },
      index: {
        count: 20,
        total: undefined,
      },
    };
    this.pageCount = 20;
  }

  componentWillReceiveProps(props) {
    if (this.props.filteredEquipment && props.filteredEquipment) {
      if (this.props.filteredEquipment.length !== props.filteredEquipment.length) {
        this.setState({
          index: {
            count: 20,
            total: (props.filteredEquipment ? props.filteredEquipment.length : undefined),
          },
        });
      }
    }
  }

  _getStatus(equipment) {
    const equipmentId = getEquipmentId(equipment.data, this.props.serviceType);
    const { validation } = { ...this.props };
    if (equipment.data.include && !validation.isFetching) {
      const issues = (!validation.isFetching && validation.properties && validation.properties ? validation.properties.getIssue(equipmentId) : undefined);
      if (issues) {
        const eqIssue = (issues.length > 0 ? issues[0] : undefined);
        return (
          <GLBMTooltip content={eqIssue && eqIssue.severity !== 'ok' ? <div>{eqIssue.label}</div> : undefined}>
            <Box align='center'>
              <StatusIcon value={eqIssue ? eqIssue.severity : 'ok'} size='small' />
            </Box>
          </GLBMTooltip>
        );
      }
    }
    return '';
  }

  _onToggleSelected = (event, equipment) => {
    equipment.selected = event.target.checked;
    this.props.onEquipmentSelection(equipment);
  };

  _sortEquipment(equipmentList, sort, locationAndTierMap) {
    if (!sort) {
      return equipmentList;
    }
    const { property: sortProperty, direction: sortDirection } = sort;
    const { columns, serviceType } = this.props;
    const columnNames = columns.map((e) => {
      if (e instanceof ServiceColumn) {
        return e.name;
      } if (e instanceof EquipmentIdColumn || e instanceof EquipmentColumn) {
        return e.field();
      }
      return '';
    });
    const index = columnNames.indexOf(sortProperty);
    const column = columns[index];
    if (!column) {
      return equipmentList;
    }
    const lookupFn = equipment => (column === ServiceColumn.LOCATION
      ? this._getEquipmentLocationText(equipment)
      : getColumnLabel(equipment.data, column, serviceType, locationAndTierMap));
    const ascending = sortDirection === 'asc';
    return equipmentList.advancedSort(ascending, lookupFn).map((el, i) => ({ ...el, index: i }));
  }

  _getEquipmentLocationText = equipment => (this.props.config.location === LocationOption.MAPPED.enumKey
    ? this.props.idLabelIndex[equipment.data.locationId]
    : equipment.data.deviceId);

  _getMappedLocation = (equipment) => {
    const effectiveTier = getEffectiveRevision(equipment.data.locationRevisions);
    const locationName = this.props.idLabelIndex && effectiveTier && this.props.idLabelIndex.hasOwnProperty(effectiveTier.id) ? this.props.idLabelIndex[effectiveTier.id] : '-';
    const editableLocation = (
      <Anchor
        onClick={() => this.props.onSingleEditSelected(equipment)}
      >
        <Text wordBreak='break-all'>
          <Highlight search={this.props.searchText}>{locationName}</Highlight>
        </Text>
      </Anchor>
    );
    return this.props.readOnly || locationName === '-'
      ? (
        <Text wordBreak='break-all'>
          <Highlight search={this.props.searchText}>{locationName}</Highlight>
        </Text>
      )
      : editableLocation;
  };

  _getTableData(column) {
    const {
      INCLUDE, SELECT, TIER, ACTION, LOCATION, RAID_TYPE,
    } = ServiceColumn;

    return AdvancedSwitch(column)
      .case(matches(INCLUDE), this._getIncludeToggleTd)
      .case(matches(SELECT), this._getSelectTd)
      .case(matches(TIER), this._getTierTd)
      .case(matches(ACTION), this._getActionTd)
      .case(matches(LOCATION), this._getLocationTd)
      .case(matches(RAID_TYPE), this._getRaidTypeTd)
      .case(instanceOf(EquipmentIdColumn), this._getEquipmentIdTd)
      .case(instanceOf(ConfigurableComponent), this._getConfigurableComponentTd)
      .default(this._getDefaultTd)
      .resolveAndInvoke(column);
  }

  _getDefaultTd = column => ({
    property: column.field(),
    header: column.header(),
    render: (datum) => {
      const equipment = datum;
      const value = this._getTableDataLabel(equipment, column, this.props.serviceType) || '-';
      return (
        (
          <Text wordBreak='break-all' id={IDUtil.getId(`DefaultTd-${column.header().replace(/ /gi, '')}`, datum.index)} key={IDUtil.getId(`DefaultTd-${column.header().replace(/ /gi, '')}`, datum.index)}>
            <Highlight search={this.props.searchText}>{value}</Highlight>
          </Text>
        )
      );
    },
  });

  _getConfigurableComponentTd = column => ({
    property: column.name || column.field(),
    header: column.header(),
    sortable: false,
    render: (datum) => {
      const equipment = datum;
      return (
        <Box key={IDUtil.getId('ConfigurableComponentTd', datum.index)} id={IDUtil.getId('ConfigurableComponentTd', datum.index)}>
          <Anchor
            key={Case.camel(column.field())}
            onClick={() => this.props.onComponentEdit(equipment)}
          >
            {equipment.data.includedComponents}
            {' '}
            of
            {' '}
            {equipment.data.components}
            {' '}
            included
          </Anchor>
        </Box>
      );
    },
  });

  _getSelectTd = column => ({
    property: column.name,
    header: column.header(),
    render: (datum) => {
      const equipment = datum;
      return (
        <Box key={IDUtil.getId('SelectTd', datum.index)} id={IDUtil.getId('SelectTd', datum.index)}>
          <CheckBox
            checked={equipment.selected}
            onChange={event => this._onToggleSelected(event, equipment)}
          />
        </Box>
      );
    },
  });

  _getLocationTd = column => ({
    property: column.name,
    header: column.header(),
    render: (datum) => {
      const equipment = datum;
      const { reportBy } = this.props.config;
      const showLocation = [ReportBy.LOCATION.enumKey, ReportBy.TIER_LOCATION.enumKey].includes(reportBy);
      if (showLocation) {
        return (
          <Box key={IDUtil.getId('LocationTd', datum.index)} id={IDUtil.getId('LocationTd', datum.index)}>
            {(this.props.config.location === LocationOption.MAPPED.enumKey)
              ? this._getMappedLocation(equipment)
              : <Text wordBreak='break-all'><Highlight search={this.props.searchText}>{equipment.data.deviceId}</Highlight></Text>}
          </Box>
        );
      }
      return '';
    },
  });

  _getRaidTypeTd = column => ({
    property: column.name,
    header: column.header(),
    primary: true,
    render: (datum) => {
      const equipment = datum;
      const value = this._getTableDataLabel(equipment, column, this.props.serviceType) || '-';
      return (
        <Box ikeyd={IDUtil.getId('RaidTypeTd', datum.index)} id={IDUtil.getId('RaidTypeTd', datum.index)}>
          {this.props.readOnly || value === '-' ? value
            : (
              <Anchor
                onClick={() => this.props.onSingleEditSelected(equipment)}
              >
                <Text wordBreak='break-all'>
                  {value}
                </Text>
              </Anchor>
            )}
        </Box>
      );
    },
  });

  _getTierTd = column => ({
    property: column.name,
    header: column.header(),
    render: (datum) => {
      const equipment = datum;
      const isReadOnlyTierEquipment = this.props.serviceType.serviceOptions.readOnlyEquipmentTierMapping;
      const isMappedTier = this.props.config.tierType === TierOptions.MAPPED.enumKey;
      if (isMappedTier) {
        const effectiveTier = getEffectiveRevision(equipment.data.mappedTierRevisions);
        const tierName = this.props.idLabelIndex && effectiveTier && this.props.idLabelIndex.hasOwnProperty(effectiveTier.id) ? this.props.idLabelIndex[effectiveTier.id] : '-';
        return (
          <Box key={IDUtil.getId('TierTd', datum.index)} id={IDUtil.getId('TierTd', datum.index)}>
            {this.props.readOnly || isReadOnlyTierEquipment || tierName === '-' ? <Highlight search={this.props.searchText}>{tierName}</Highlight>
              : (
                <Anchor
                  onClick={() => this.props.onSingleEditSelected(equipment)}
                >
                  <Text wordBreak='break-all'>
                    <Highlight search={this.props.searchText}>{tierName}</Highlight>
                  </Text>
                </Anchor>
              )}
          </Box>
        );
      }
      return '';
    },
  });

  _getActionTd = column => ({
    property: column.name,
    header: column.header(),
    sortable: false,
    render: (datum) => {
      const equipment = datum;
      return (
        <Box key={IDUtil.getId('ActionTd', datum.index)} id={IDUtil.getId('ActionTd', datum.index)}>
          <Button icon={<Edit />} onClick={() => this.props.onSingleEditSelected(equipment)} />
        </Box>
      );
    },
  });

  _getEquipmentIdTd = column => ({
    property: column.field(),
    header: column.header(),
    primary: true,
    render: (datum) => {
      const equipment = datum;
      const isEOST = moment.utc().isAfter(moment(equipment.data.decommissionDate));
      const equipmentColumn = this._getTableDataLabel(equipment, column, this.props.serviceType);
      const id = getEquipmentId(equipment.data, this.props.serviceType);
      const { reportBy } = this.props.config;
      const showLocation = [ReportBy.LOCATION.enumKey, ReportBy.TIER_LOCATION.enumKey].includes(reportBy);
      const isMappedTier = this.props.config.tierType === TierOptions.MAPPED.enumKey;
      const isMappedLocation = showLocation && (this.props.config.location === LocationOption.MAPPED.enumKey);
      return (
        <Box direction='row' align='center' gap='xxsmall' id={IDUtil.getId('EquipmentIdTd', datum.index)} key={IDUtil.getId('EquipmentIdTd', datum.index)}>
          {this._getStatus(equipment)}
          <Box>
            {this.props.readOnly ? equipmentColumn
              : <Anchor onClick={this.props.onSingleEditSelected.bind(this, equipment)}><Text wordBreak='break-all'><Highlight search={this.props.searchText}>{equipmentColumn}</Highlight></Text></Anchor>}
          </Box>
          <Box direction='row' pad={{ start: 'small' }} gap='small'>
            {equipment.data.decommissionDate
              ? (
                <GLBMTooltip content={<div>{`End of System Term: ${moment(equipment.data.decommissionDate).format('YYYY-MM-DD')} (${equipment.data.decommissionNotes})`}</div>}>
                  <FormClock type='status' color={isEOST ? '#cccccc' : ''} />
                </GLBMTooltip>
              )
              : ''}
            {((equipment.data.mappedTierRevisions && equipment.data.mappedTierRevisions.length > 1) || (equipment.data.locationRevisions && equipment.data.locationRevisions.length > 1))
              && (
                <ResourceRevisionTooltip
                  showTier={isMappedTier}
                  showLocation={isMappedLocation}
                  equipment={equipment.data}
                  idMap={this.props.idLabelIndex}
                />
              )}
          </Box>
        </Box>
      );
    },
  });

  _getIncludeToggleTd = column => ({
    property: column.name,
    header: column.header(),
    render: (datum) => {
      const equipment = datum;
      const id = getEquipmentId(equipment.data, this.props.serviceType);
      return (
        <GLBMTooltip
          key={IDUtil.getId('EquipmentIdTd', datum.index)}
          content={equipment.data.decommissionDate && (
            <>
              {pluralize(this.props.serviceType.label)}
              {' '}
              with an end of system term date must be included
            </>
          )}
        >
          <Box
            width='60px'
            key={Case.camel(column.name)}
            id={IDUtil.getId('IncludeToggleTd', datum.index)}
          >
            <CheckBox
              toggle={true}
              checked={equipment.data.include}
              disabled={!!equipment.data.decommissionDate || this.props.readOnly}
              onChange={event => this.props.onToggleInclude(event, equipment)}
              pad='none'
              margin='none'
            />
          </Box>
        </GLBMTooltip>
      );
    },
  });

  _getTableDataLabel(equipment, column, serviceType, map) {
    const fieldFn = () => `data.${column.field(serviceType)}`;
    return getServiceLabel(equipment, column, serviceType, map, fieldFn);
  }

  _getColumns(columns, searchText, isLoading, count) {
    return columns.map(column => this._getTableData(column));
  }

  _onSortColumn(sort) {
    this.setState({ sort });
  }

  render() {
    const equipmentList = this._sortEquipment(this.props.filteredEquipment, this.state.sort);

    const { columns, page, step } = { ...this.props };
    const { index } = { ...this.state };
    index.total = (equipmentList && equipmentList.length ? equipmentList.length : undefined);
    const dataChunks = chunk(equipmentList, step);
    return (
      <GLBMDataTable
        key={`ConfigureResourcesTable-${page}`}
        pin={true}
        columns={this._getColumns(columns, this.props.searchText, this.state.loading, equipmentList.length)}
        data={dataChunks[page - 1]}
        loading={this.state.loading}
        total={index.total}
        onSort={sort => this._onSortColumn(sort)}
        sort={this.state.sort}
      />
    );
  }
}

ConfigureResourcesTable.propTypes = {
  readOnly: PropTypes.bool,
  page: PropTypes.number,
  step: PropTypes.number,
  config: PropTypes.object,
  columns: PropTypes.arrayOf(PropTypes.object),
  serviceType: PropTypes.object,
  idLabelIndex: PropTypes.object,
  filteredEquipment: PropTypes.arrayOf(PropTypes.object),
  validation: PropTypes.object,
  labels: PropTypes.arrayOf(PropTypes.any),
  sort: PropTypes.object,
  onComponentEdit: PropTypes.func,
  onSingleEditSelected: PropTypes.func,
  onEquipmentSelection: PropTypes.func,
  showPending: PropTypes.bool,
  onToggleInclude: PropTypes.func,
};

export default ConfigureResourcesTable;
