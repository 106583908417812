// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useMemo } from 'react';
import {
  Box,
  CheckBox,
  ColumnConfig,
  DataTable,
  FormField,
  Heading,
  Text,
  TextInput
} from 'grommet';
import { ExceptionsFormValues } from './types';
import ServiceTypeStore from '../stores/ServiceTypeStore';
import { CapacityAlertExceptionDefinition } from '../../core/types';
import { validateNumber, validateNumber0to100 } from './utils';

interface Step {
  location?: { name: string, id: string },
  tier: { name: string, id: string }
  title: string
  namePrefix: string
}

const combinedTierAndLocations = ({ locations, tiers } : CapacityAlertExceptionDefinition[string][string], serviceType: string) => {
  const result: Step[] = [];

  if (!locations) {
    // eslint-disable-next-line no-plusplus
    for (let t = 0; t < tiers.length; t += 1) {
      result.push({
        tier: tiers[t],
        title: tiers[t].name,
        namePrefix: `${serviceType}.${tiers[t].id?.replace(/\./gi, ',')}.undefined`
      });
    }
  } else {
    // eslint-disable-next-line no-plusplus
    for (let l = 0; l < locations.length; l += 1) {
      // eslint-disable-next-line no-plusplus
      for (let t = 0; t < tiers.length; t += 1) {
        result.push({
          location: locations[l],
          tier: tiers[t],
          title: `${locations[l].name} ${tiers[t].name}`,
          // replace . with , to avoid issues with location as ip address. When for location 10.192.12.12 it creates nested objects {10: {192: {12: {12: {}}}}}
          namePrefix: `${serviceType}.${tiers[t].id?.replace(/\./gi, ',')}.${locations[l].id?.replace(/\./gi, ',')}`
        });
      }
    }
  }
  return result;
};

interface Props {
  serviceType: string
  definitions: CapacityAlertExceptionDefinition[string][string]
  formValues: ExceptionsFormValues
  canEdit: boolean
}

const ServiceTypeExceptionsTable = ({
  serviceType,
  definitions,
  formValues,
  canEdit,
}: Props) => {
  const serviceTypeEnum = ServiceTypeStore.getService(serviceType);
  const steps = useMemo(() => combinedTierAndLocations(definitions, serviceType), [definitions, serviceType]);

  const columns = useMemo<ColumnConfig<Step>[]>(() => [
    {
      header: 'Disable alerts',
      size: 'xsmall',
      property: 'excluded',
      render: ({ namePrefix }) => (
        <CheckBox
          name={`${namePrefix}.excluded`}
          toggle={true}
          disabled={!canEdit}
          id={`ExceptionListItemExcludedSwitch${namePrefix}`}
        />
      )
    },
    {
      header: definitions.reportBy === 'TIER' ? 'Tier' : 'Tier and Location',
      property: 'title',
      primary: true,
    },
    {
      header: '% Free',
      size: 'small',
      property: 'title',
      render: ({ namePrefix, tier, location }) => {
        const excluded = !!formValues?.[serviceType]?.[tier.id]?.[location?.id ?? 'undefined']?.excluded;
        return (
          <FormField
            name={`${namePrefix}.pctFree`}
            disabled={excluded}
            readOnly={!canEdit}
            validate={validateNumber0to100}
          >
            <TextInput
              name={`${namePrefix}.pctFree`}
              disabled={excluded}
              readOnly={!canEdit}
              id={`ExceptionListItemPctFreeInput${namePrefix}`}
            />
          </FormField>
        );
      }
    },
    {
      header: '% Change',
      size: 'small',
      property: 'title',
      render: ({ namePrefix, tier, location }) => {
        const excluded = !!formValues?.[serviceType]?.[tier.id]?.[location?.id ?? 'undefined']?.excluded;
        return (
          <FormField
            name={`${namePrefix}.pctChange`}
            disabled={excluded}
            readOnly={!canEdit}
            validate={validateNumber}
          >
            <TextInput
              name={`${namePrefix}.pctChange`}
              disabled={excluded}
              readOnly={!canEdit}
              id={`ExceptionListItemPctChangeInput${namePrefix}`}
            />
          </FormField>
        );
      }
    }
  ], [canEdit, definitions.reportBy, formValues, serviceType]);

  return (
    <Box key={serviceType} flex={false}>
      <Heading level={3}>{serviceTypeEnum.label}</Heading>
      {steps.length > 0 && (
        <DataTable
          data-testid={`table-${serviceType}`}
          data={steps}
          columns={columns}
          primaryKey='namePrefix'
        />
      )}
      {steps.length === 0 && (
        <Box align='center'>
          <Text>No available exceptions.</Text>
        </Box>
      )}
    </Box>
  );
};

export default ServiceTypeExceptionsTable;
